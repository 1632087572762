import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CategoryForm from '@/core/components/common/forms/category/CategoryForm.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
export default defineComponent({
    name: 'post-project-categories-component',
    emits: ['on-update-value'],
    components: {
        CategoryForm,
    },
    data() {
        return {
            categories: []
        };
    },
    created() {
        const { getProjectCategories } = this;
        if (getProjectCategories && getProjectCategories.length) {
            this.categories = getProjectCategories;
            this.getData(this.categories);
        }
    },
    computed: {
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['getProjectCategories'])
    },
    methods: {
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, ['setProjectCategories']),
        getData(categories) {
            const subCategoryIds = [];
            for (const category of categories) {
                const { subCategories } = category;
                for (const subCategory of subCategories) {
                    const { id } = subCategory;
                    if (!subCategoryIds.includes(id)) {
                        subCategoryIds.push(id);
                    }
                }
            }
            this.setProjectCategories(categories);
            this.$emit('on-update-value', {
                type: 'categories',
                value: subCategoryIds
            });
        },
    }
});
