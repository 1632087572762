<template>
    <section class="description-container">
         <el-input v-model="value.name" placeholder="Project title" clearable />
         <el-input type="textarea" v-model="value.description" placeholder="Enter Description" :rows="15"></el-input>

        <!-- <RichTextEditor /> -->
    </section>
</template>
<script>
// import { ref } from 'vue';

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

// import RichTextEditor from '@/modules/project-post/components/RichTextEditor.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({

  components: {
    // RichTextEditor,
  },

  emits: ['on-update-value'],

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.name || value.description) {
          this.onUpdateValue(value);
        }
      }
    }
  },

  data() {
    return {
      value: {
        name: '',
        description: ''
      }
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['getProjectDescription']),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject',
    ]),
  },

  methods: {
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, ['setProjectDescription']),

    initialize() {
      const { getProjectDescription } = this;

      if (getProjectDescription) {
        this.value = {
          name: getProjectDescription.name,
          description: getProjectDescription.description
        };
      }
    },

    onUpdateValue(value) {
      this.setProjectDescription(value);
      this.$emit('on-update-value', {
        type: 'name',
        value
      });
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.description-container {
   height: 100%;
   border-radius: 8px;
   display:flex;
   flex-direction: column;
   gap: 2rem;

   :deep(.el-input__inner) {
    background: transparent;
    padding: 2rem 2rem 2rem .5rem;
    font-size: 2rem;
    border: none;
    border-bottom: 2px solid rgba(12, 15, 74, 0.1);
   }

   :deep(.el-textarea__inner) {
    padding: 20px;
    font-size: 16px;
    resize: none;
    border-radius: 8px;
    border:none;
    height: 250px;
   }
}

@include media-sm-max-width() {
  .description-container {
    :deep(.el-textarea__inner) {
      height: 220px;
    }
  }
}
</style>
