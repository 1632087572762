<template>
    <section class="post-project-page-container jc-sb" v-if="hasMainLayoutInitialized">
      <div class="post-project-page-container__content flex-column">
        <div class="menu-mobile">
          <MobileStepsComponent/>
        </div>
        <div class="title-container">
          <p class="title default-text">{{header}}</p>
          <!-- <p class="cta-save-mobile">Save draft</p> -->
        </div>
        <div class="post-project-content">
          <SelectStatusComponent v-if="getCurrentJourney === STATUS_SCREEN" @on-update-value="onUpdateFormData"/>
          <!-- <InviteOthersComponent v-if="getCurrentJourney === INVITE_SCREEN"/> -->
          <DescriptionComponent v-if="getCurrentJourney === DESCRIPTION_SCREEN" @on-update-value="onUpdateFormData"/>
          <CategoriesComponent v-if="getCurrentJourney === CATEGORIES_SCREEN" @on-update-value="onUpdateFormData"/>
          <!-- <BudgetComponent v-if="getCurrentJourney === BUDGET_SCREEN" @on-update-value="onUpdateFormData"/> -->
          <AttachmentsComponent v-if="getCurrentJourney === ATTACHMENTS_SCREEN"
            :projectAttachments="formData.attachments"
            @on-update-value="onUpdateAttachments"
            @on-progress-upload="onProgressUpload"
          />
        </div>
        <el-scrollbar class="main-menu-container">
          <PostProjectMenuComponent @on-next-step="onNextStep" :requiredSteps="requiredSteps"/>
        </el-scrollbar>
      </div>

    <div class="action-container">
      <el-button type="primary" class="button yellow-button flex-row ai-c jc-c default-text" :disabled="isSubmittingProject" round @click="onNextStep(getCurrentJourney)">
        {{ isSubmittingProject ? 'Saving..' : (setBtnLabel ? setBtnLabel : 'Next') }}
      </el-button>
      <!-- <el-button type="primary" class="button back-button flex-row ai-c jc-c default-text show-in-mobile" round @click="onBack">
        Back
      </el-button> -->
      <SponsorTag/>
    </div>
    <!-- MODAL -->
    <PostProjectConfirmationModal :show="getPostProjectConfirmationModal" :loading="isSubmittingProject" @on-cancel="setPostProjectConfirmationModal(false)" @on-confirm="createNewProject"/>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import {
  ATTACHMENTS_SCREEN, BUDGET_SCREEN, CATEGORIES_SCREEN, DESCRIPTION_SCREEN, INVITE_SCREEN, STATUS_SCREEN
} from '@/core/components/project-post/constants';
import SponsorTag from '@/core/components/ui/SponsorTag.vue';
import { ProjectJobStatusEnum, ProjectStatusesEnum } from '@/core/packages/shared-library';
import PostProjectConfirmationModal from '@/modules/project-post/components/modal/PostProjectConfirmationModal.vue';
// import { PROJECT_DETAILS } from '@/modules/project-details/routes';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

import AttachmentsComponent from '../components/AttachmentsComponent.vue';
// import BudgetComponent from '../components/BudgetComponent.vue';
import CategoriesComponent from '../components/CategoriesComponent.vue';
import DescriptionComponent from '../components/DescriptionComponent.vue';
// import InviteOthersComponent from '../components/InviteOthersComponent.vue';
import MobileStepsComponent from '../components/MobileStepsComponent.vue';
import PostProjectMenuComponent from '../components/PostProjectMenuComponent.vue';
import SelectStatusComponent from '../components/SelectStatusComponent.vue';

// const STATUS_SCREEN = 'Status';
// const INVITE_SCREEN = 'Invite';
// const DESCRIPTION_SCREEN = 'Description';
// const CATEGORIES_SCREEN = 'Categories';
// const BUDGET_SCREEN = 'Budget';
// const ATTACHMENTS_SCREEN = 'Attachments';

export default {
  name: 'post-project-page',
  components: {
    AttachmentsComponent,
    // BudgetComponent,
    DescriptionComponent,
    // InviteOthersComponent,
    SelectStatusComponent,
    PostProjectMenuComponent,
    CategoriesComponent,
    MobileStepsComponent,
    PostProjectConfirmationModal,
    SponsorTag
  },
  data() {
    return {
      header: 'Please select the status of your project',
      headerObj: {
        Status: 'Please select the status of your project',
        Invite: 'Invite others to join your project?',
        Description: 'Please describe your project',
        Categories: 'Select the skill categories required for the project.',
        Budget: 'Have you got a budget in mind?',
        Attachments: 'Upload attachments'
      },
      formData: {
        propertyId: null,
        projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
        projectStatusId: ProjectStatusesEnum.READY_FOR_HIRE,
        minBudget: 0,
        maxBudget: 250000,
        attachments: [],
        postcode: '',
        latitude: 0,
        longitude: 0
      },

      // tmp
      validFields: [
        'name',
        'description',
        'type',
        'minBudget',
        'maxBudget',
        'categories',
        'attachments'
      ],

      isSubmittingProject: false,

      requiredSteps: [],

      setBtnLabel: '',

      property: null,
      STATUS_SCREEN,
      INVITE_SCREEN,
      DESCRIPTION_SCREEN,
      CATEGORIES_SCREEN,
      BUDGET_SCREEN,
      ATTACHMENTS_SCREEN
    };
  },
  watch: {
    getCurrentJourney(value) {
      this.header = this.headerObj[value];
    }
  },
  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
      'getCurrentJourney',
      'unsavedProjectMembers',
      'getPostProjectConfirmationModal',
      'getCancelPageModal',
      'getLeavePageModal'
    ]),

    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },

  beforeUnmount() {
    this.setCurrentSelectedProject(null);
    this.resetJourneyCompleted();
    this.setProjectDescription({
      name: '',
      description: ''
    });
    this.setProjectCategories([]);
    this.setStatusProjectType({
      status: {
        type: '',
        standardType: '',
        standardDate: [],
        routineDate: '',
        routineDuration: 0
      }
    });
    this.setJourneyCompleted([]);
    this.setUnsavedProjectMembers([]);
    this.setProjectBudget({
      min: 0,
      max: 250000,
      hasChanged: 0
    });
    this.setCurrentJourney('Status');
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'setCurrentSelectedProject',
      'setToBePassedCategories',
      'setSubCategories',
      'setMainCategories'
    ]),

    ...mapActions(PROPERTY_STORE, ['getProperty']),

    ...mapActions(PROJECT_POST_STORE, ['saveProject']),
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
      'setCurrentJourney',
      'setJourneyCompleted',
      'setUnsavedProjectMembers',
      'setProjectBudget',
      'setStatusProjectType',
      'createProjectMembers',
      'setAddedProjectMembers',
      'setProjectCategories',
      'setProjectDescription',
      'resetJourneyCompleted',
      'setPostProjectConfirmationModal',
      'setCancelPageModal',
      'setLeavePageModal'
    ]),

    async initialize() {
      const { params } = this.$route;
      const { propertyId } = params;

      if (propertyId) {
        this.formData.propertyId = parseInt(propertyId, 10);

        this.property = await this.getProperty(propertyId);

        const { addressData, postcode } = this.property;

        this.formData.postcode = postcode;

        const addressDataObj = JSON.parse(addressData);
        const { latlng } = addressDataObj;
        const [latitude, longitude] = latlng;

        this.formData.latitude = `${latitude}`;
        this.formData.longitude = `${longitude}`;
      }
    },

    onNextStep(selectedMenu = null) {
      let skippedMenu = false;
      const currentMenu = selectedMenu || this.getCurrentJourney;
      const { formData, validFields } = this;
      let value = null;
      let counter = 0;

      if (!selectedMenu) {
        this.setCurrentJourney(this.STATUS_SCREEN); // default
        return;
      }

      if (this.getCurrentJourney !== selectedMenu) {
        skippedMenu = this.getCurrentJourney;
      }

      if (currentMenu === this.STATUS_SCREEN) {
        this.onCompleteStep(currentMenu, this.DESCRIPTION_SCREEN, formData?.type, skippedMenu);
        return;
      }
      // if (currentMenu === this.INVITE_SCREEN) {
      //   this.onCompleteStep(currentMenu, this.DESCRIPTION_SCREEN, this.unsavedProjectMembers.length, skippedMenu);
      //   return;
      // }
      if (currentMenu === this.DESCRIPTION_SCREEN) {
        const { name, description } = formData;

        if (name || description) {
          value = {
            name,
            description
          };
        }

        this.onCompleteStep(currentMenu, this.CATEGORIES_SCREEN, value, skippedMenu);
        return;
      }
      if (currentMenu === this.CATEGORIES_SCREEN) {
        this.onCompleteStep(currentMenu, this.ATTACHMENTS_SCREEN, formData?.categories?.length, skippedMenu);
        return;
      }
      // if (currentMenu === this.BUDGET_SCREEN) {
      //   this.onCompleteStep(currentMenu, this.ATTACHMENTS_SCREEN, formData?.minBudget, skippedMenu);
      //   return;
      // }

      if (currentMenu === this.ATTACHMENTS_SCREEN) {
        this.setJourneyCompleted(this.ATTACHMENTS_SCREEN);
        // this.formData.attachments = []; // empty for now
      }

      const fields = Object.keys(formData);
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (validFields.includes(field)) {
          counter += 1;
        }
      }
      const isFormValid = counter === validFields.length;

      if (isFormValid && this.setBtnLabel) {
        // this.createNewProject();
        this.setPostProjectConfirmationModal(true);
      }

      if (isFormValid) {
        this.setBtnLabel = 'GET ESTIMATES';
      } else {
        // set requiredSteps
      }
    },

    onCompleteStep(type, next, value, skippedMenu) {
      const completeType = skippedMenu || type;

      this.setCurrentJourney(next);

      if (value) {
        this.setJourneyCompleted(completeType);
      }
    },

    onUpdateFormData(data) {
      const { type, value } = data;
      const { formData } = this;
      let newValue = value;
      let newType = type;

      if (type === 'projectType') {
        newType = 'type';
        newValue = value.type;

        if (value?.standardDate && value?.standardDate.length) {
          const [startDate, endDate] = value.standardDate;

          formData.startDate = startDate;
          formData.endDate = endDate;
        }
      }

      if (type === 'name') {
        formData[type] = newValue.name;
        formData.description = newValue.description;

        return;
      }

      if (type === 'budget') {
        const { min, max } = newValue;

        formData.minBudget = min;
        formData.maxBudget = max;

        return;
      }

      if (type === 'categories' && !newValue.length) {
        newValue = null;
      }

      if (newValue) {
        formData[newType] = newValue;
      }
    },

    onUpdateAttachments(attachments) {
      this.formData.attachments = attachments;
    },

    onProgressUpload() {

    },

    async createNewProject() {
      const project = this.formData;

      this.isSubmittingProject = true;

      await this.saveProject(project)
        .then(async (response) => {
          if (response) {
            // await this.saveProjectMembers(response);
            this.$notify.success({
              title: 'Project Creation Successful',
              message: 'Successfully posted the project',
            });
            this.setToBePassedCategories([]);
            this.setSubCategories([]);
            this.setMainCategories([]);
            this.$router.push(`/projects/${response.refId}/project-details/overview`);
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Project Creation Failed',
            message: e.data.message || 'Error occurred when creating the project.',
          });
        })
        .finally(() => {
          this.isSubmittingProject = false;
          this.setPostProjectConfirmationModal(false);
        });
    },

    async saveProjectMembers(response) {
      const { id: projectId } = response;
      const unsavedProjectMembers = this.unsavedProjectMembers.map((item) => {
        return {
          memberPermissionId: item.role,
          email: item.email
        };
      });

      if (unsavedProjectMembers?.length) {
        await this.createProjectMembers({
          projectId,
          formData: { projectMembers: unsavedProjectMembers }
        }).then(async () => {
          this.setUnsavedProjectMembers([]);
          this.setAddedProjectMembers(true);
        })
          .catch(() => {
            this.$notify.error({
              message: 'Error saving members. Check if the email already exist.'
            });
          })
          .finally(() => {
          });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.post-project-page-container * p {
  margin: 0;
}
.post-project-page-container {
  // background-color: red;
  width: 68%;
  margin-left: 10px;
  // margin-right: 20px;
  display:flex;
  flex-direction: column;
  // height:100%;
  // width: 100%;
  gap: 2rem;
  padding-bottom: 5rem;
  max-width:1030px;
  height: calc(100% - 90px);
  justify-content: space-between;
  position: relative;
  // background: red;

  &__content {
    gap: 1.5rem;
  }

  .menu-mobile {
    display: none;
  }
  .title-container {
    .title {
      font-family: Mulish;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0px;
    }
    .cta-save-mobile {
      display: none;
    }
  }
  .post-project-content {
    width: 100%;
  }

  .main-menu-container {
      // width: 28%;
      max-width : 420px;
      // height: 100%;
      position: absolute;
      top: 0;
      right: -230px;
      // background: green;
  }

  .action-container {
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 150px;
    flex-direction: column;
    gap: .7rem;

    .yellow-button {
        background: #FAA100;
        border-radius: 7px;
        color: #fff;
        outline: none;
        border: none;
        width: 100%;
    }
    .back-button {
      background: transparent;
      color: rgba(12, 15, 74, 0.5);
      border:none;
      border-radius: 7px;
      width: 150px;
    }
  }
}
@include media-md-max-width() {
  .post-project-page-container {
    height: 100%;
    position: relative;
    padding-bottom: 0;
    &__content {
      height: 100%;
    }
    .post-project-content {
      max-height: calc(100vh - 300px);
    }
    .action-container {
      // margin-bottom: 1.2rem;
      position: absolute;
      bottom: 1rem;
    }
  }
}

@include media-sm-max-width() {
  .post-project-page-container {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    .menu-mobile {
      display: block;
      .el-steps {
          width: 100%;

          :deep(.el-step__head.is-success) {
              color: #fff;
              border-color: #FFA500;
              .el-step__line {
                  height: 3px;
                  background-color:  #FFA500;
              }
              .el-step__icon.is-text {
                  background-color: #FFA500;
              }
          }
          :deep(.el-step__head.is-process) {
              color: #FFA500;
              border-color: #FFA500;
              .el-step__line {
                  background-color:  rgba(12, 15, 74, 0.05);
              }
              .el-step__icon.is-text {
                  background-color: rgba(12, 15, 74, 0.05);
                  border: 3px solid;
                  .el-step__icon-inner {
                      font-size: 10px;
                      font-weight: 700;
                      line-height: 25px;
                      letter-spacing: 0.25px;
                      text-align: center;
                  }
              }
          }
          :deep(.el-step__head.is-wait) {
              color: rgba(12, 15, 74, 0.5);
              border-color: rgba(12, 15, 74, 0.05);
              .el-step__line {
                  // height: 3px;
                  background-color:  rgba(12, 15, 74, 0.05);
              }
              .el-step__icon.is-text {
                  background-color: rgba(12, 15, 74, 0.05);
                  border: none;
                  .el-step__icon-inner {
                      font-size: 10px;
                      font-weight: 700;
                      line-height: 25px;
                      letter-spacing: 0.25px;
                      text-align: center;
                  }
              }
          }
      }
    }

    .title-container {
       display: flex;
       width: 100%;
       justify-content: space-between;
       align-items: center;

      .cta-save-mobile {
        display: inline-block;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        color: #FAA100;
        cursor: pointer;
      }
    }

    .main-menu-container {
      display:none;
    }
  }
}

@media screen and (max-height: 690px) {
  .post-project-page-container {
    // margin-bottom: 4rem;
    margin-bottom: 0;
  }
}
@include media-sm-max-width() {
  .post-project-page-container {
    // height: calc(100% - 80px);
    height: 100%;
    padding-bottom: 0;
    // height: calc(100% - 15px);
    gap: 1rem;
    position: relative;
    // position: relative;

    &__content {
      max-height: calc(100% - 4rem);
      height: 100%;
    }
    .post-project-content {
      max-height: calc(100vh - 360px);
    }
    .title-container {
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 23px;
      }
    }
    // .action-container {
    //   // margin-bottom: 1.2rem;
    //   position: absolute;
    //   bottom: 1rem;
    // }
  }
}
@include media-xs-max-width() {
  .post-project-page-container {
    .action-container {
      align-items: center;
      justify-content: center;
      width: 100%;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      bottom: 3rem;

      .button {
        width: 100%;
      }
    }
  }
}
</style>
