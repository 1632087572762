import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
export default defineComponent({
    emits: ['on-update-value'],
    data() {
        return {
            disabledDate(date) {
                return date < moment(new Date()).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toDate();
            },
            serviceDurations: [
                { label: 'Weekly', value: 7 },
                { label: 'Every 2 weeks', value: 14 },
                { label: 'Monthly', value: 30 },
                { label: 'Every 3 months', value: 90 },
                { label: 'Every 6 months', value: 180 },
                { label: 'Yearly', value: 360 },
            ],
            projectType: {
                type: '',
                standardType: '',
                standardDate: [],
                routineDate: '',
                routineDuration: 0
            }
        };
    },
    watch: {
        projectType: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.type) {
                    this.onUpdateValue(value);
                }
            },
        },
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['getStatusProjectType'])
    },
    methods: {
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, ['setStatusProjectType']),
        selectProjectType(type) {
            this.projectType = {
                type,
                standardType: '',
                standardDate: [],
                routineDate: '',
                routineDuration: 0
            };
        },
        selectStandardType(type) {
            this.projectType.standardType = type;
        },
        selectRoutineService(value) {
            this.projectType.routineDuration = value;
        },
        handleNext() {
            // this.setStatusProjectType(this.projectType);
        },
        initialize() {
            this.projectType = this.getStatusProjectType;
        },
        onUpdateValue(value) {
            this.setStatusProjectType(value);
            this.$emit('on-update-value', {
                type: 'projectType',
                value
            });
        }
    }
});
