<template>
    <nav class="menu-container">
        <el-menu :default-active="getCurrentJourney" :router="false" ref="projectSidebar" class="el-menu-vertical menu-container" background-color="transparent" text-color="rgba(12, 15, 74, 0.5)"  active-text-color="#FAA100">
            <el-menu-item class="menu-item" v-for="(menu, i) in menus" :key="i" @click="onNextStep(i, menus)" :index="menu">
                <span><el-icon :size="20" color="rgba(12, 15, 74, 0.5)"><Check v-if="getJourneyCompleted.includes(menu)"/></el-icon></span>
                <p class="count">{{i + 1}}</p>
                <p class="name">{{menu}}</p>
            </el-menu-item>
        </el-menu>
    </nav>
</template>
<script>
import { Check } from '@element-plus/icons';
import { mapActions, mapGetters } from 'vuex';

import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';

export default {
  components: {
    Check,
  },

  emits: ['on-next-step'],
  //   initial data and algo for menu
  data() {
    return {
      menus: ['Status', 'Description', 'Categories', 'Attachments']
    };
  },
  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['getJourneyCompleted', 'getCurrentJourney'])
  },

  created() {
  },

  methods: {
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, ['setCurrentJourney']),

    onNextStep(index, menus) {
      const menu = index ? menus[index - 1] : null;

      this.$emit('on-next-step', menu);
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-container {
    position:relative;
    padding:3rem .5rem 0 1rem;
    width: 100%;

    .el-menu {
        margin-top: 2rem;
    }

    .el-menu-item {
        width: 100%;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        .count {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0px;
            margin: 0;
            font-family: Mulish;
        }
        .name {
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.10000000149011612px;
            margin: 0;
            font-family: Mulish;
        }
    }

    .el-menu-item:hover {
        background: transparent;
    }
}
</style>
