<template>
    <div class="mobile-steps flex-row ai-c jc-sb">
        <div class="mobile-steps__item flex-row ai-c jc-c" v-for="(menu, i) in menus" :key="i" :class="{'active': getCurrentJourney === menu || getJourneyCompleted.includes(menu)}">
           <el-icon :size="15" color="#FFF" v-if="getJourneyCompleted.includes(menu)"><Check/></el-icon>
           <p class="mobile-steps__item-text default-text" v-else>{{i + 1}}</p>
        </div>
    </div>
</template>
<script>
import { Check } from '@element-plus/icons';
import { mapActions, mapGetters } from 'vuex';

import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';

export default {
  components: {
    Check
  },
  data() {
    return {
      menus: ['Status', 'Description', 'Categories', 'Attachments']
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, ['getJourneyCompleted', 'getCurrentJourney'])
  },
  methods: {
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, ['setCurrentJourney']),
  }
};
</script>
<style lang="scss" scoped>
.mobile-steps {
    width: 100%;

    &__item {
       width: 24px;
       height: 24px;
       background: rgba(12, 15, 74, 0.05);
       border-radius: 100px;
       position: relative;

    //    &::after {
    //     content: "";
    //     position: absolute;
    //     display: inline-block;
    //     height: 2px;
    //     vertical-align: bottom;
    //     width: 100%;
    //     margin-right: -100%;
    //     margin-left: 41px;
    //     background: rgba(12, 15, 74, 0.05);
    //    }
    //    &:last-child {
    //         &::after {
    //             content: "";
    //             display: none;
    //         }
    //    }
    }
    &__item-text {
        font-size: 12px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        color:rgba(12, 15, 74, 0.5);
    }
    .active {
        background:#FFA500;

        .mobile-steps__item-text {
          color: #FFF;
        }
    }
}
</style>
